:root {
  --padding-responsive: 5px 1vw;
  --margin-responsive: 0 1vw;
  --width-responsive: 98vw;
}

@media (max-width: 479px) {
  :root {
    --padding-responsive: 10px 2vw;
    --margin-responsive: 0 2vw;
    --width-responsive: 96vw;
  }
}

body {
  background-color: #fff;
  margin: 0;
  -ms-overflow-style: none;
  font-size: 16px;
}

body::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.inline {
  display: inline;
}

.pointer {
  cursor: pointer;
  text-decoration: underline;
}

h1 {
  margin: 0;
  font-weight: 800;
  letter-spacing: 8px;
}

p {
  margin: 0;
  text-transform: uppercase;
  font-weight: 700;
}

@media (max-width: 479px) {
  h1 {
    font-size: 1.3rem;
  }

  p {
    font-size: 0.8rem
  }
}
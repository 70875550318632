.button {
  position: fixed;
  padding: var(--padding-responsive);
  mix-blend-mode: difference;
  color: #fff;
  cursor: pointer;
  z-index: 1000;
}

.container {
  position: fixed;
  width: 100vw;
  background-color: #000;
  padding: 2vh 0;
  z-index: 10000;
  color: rgb(180, 180, 180);
  transform: translateY(-100%);
  opacity: 0;
  transition: all 0.3s ease-in-out;

  &.open {
    transform: translateY(0);
    opacity: 1;
  }

  div:last-child {
    display: flex;
    margin-top: 8vh;
    justify-content: center;
    gap: 7vw;
  }
}

.container p,
.button {
  transition: color 0.3s ease;
}

.container p:hover,
.button:hover {
  color: #fff;
}

.closeButton {
  position: fixed;
  top: 0;
  left: 0;
  padding: var(--padding-responsive);
  font-size: 1rem;
  cursor: pointer;
}